import React from "react"
import { useState } from "react";
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroDark from "../components/Hero/HeroDark"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"


const IndexPage = () => {
 const [name, setName] = useState<string>("");
const [email, setEmail] = useState<string>("");
const [contact_number, setcontact_number] = useState<string>("");
const [message, setMessage] = useState<string>("");
  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let bodyRequest:any={
        "name":name,
        "email":email,
        "contact_number":contact_number,
        "website":"book-a-demo"
        
      }
      let res = await fetch("http://localhost:5000/api/saveform", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(bodyRequest)
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setName("");
        setEmail("");
        setMessage("User created successfully");
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [shopStat, setshopChange] = useState(true);

  
  return (
  <Layout>
    <SEO title="Book a Demo" />
    
    <div className=" bg1 p-[15px]">   
    <br/>

     <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 place-items-center ">
     
  <div className="items-end ml20"> <span className="hm42">Book a demo</span>

  <h1 className="hm41">  {shopStat === true ? "Start YourHyperautomation Journey Here" : "Congratulations you've taken the first step towards Hyper Automation"}</h1>
  <p className="hm43 mt-3"> {shopStat === true ? "Book a slot for a one-to-one product demo with one of our AI solution experts.30 mins is all it takes to boost your productivity by 3X." : "One small step for you, one giant leap for your organization."}</p>
  <div className="grid grid-cols-3  mt-4 ">
    <div>
  <img src="/images/frame.png" alt="facebook" />
  <p className="mt-4 fs12">Identify Use Case</p>
  </div>
  <div>
    <img src="/images/frame.png" className="ml-2 "  alt="facebook" /> 
    <p className="mt-4 fs12">Get A road map for success</p>
    </div>
    <div>
     <img src="/images/frame.png" className="ml-4"alt="facebook" />
     <p className="mt-4 fs12">Project RDI & Target Quick Wins</p>
     </div>
</div>
  </div>
  <div>

  <form onSubmit={handleSubmit} className={shopStat===true ? "b1" : "b2"}>
  <div className="mb-4">
    <label className="block mb-2 text-lg font-medium bg1">Name</label>
    <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="wid25r h-3 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="" />
  </div>
  <div className="mb-4">
    <label  className="block mb-2 text-lg font-medium bg1">Email</label>
    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="wid25r shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" />
  </div>
  <div className="mb-6">
    <label className="block mb-2 text-lg font-medium bg1">Mobile Number</label>
    <input  value={contact_number} onChange={(e) => setcontact_number(e.target.value)} className="wid25r shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" />
  </div>
 <p className="fs12">By proceeding, I agree to Rapid Accelaration Partners, Inc’s Terms of Use and acknowledge<br/>that I have read the Privacy Policy.</p>
  <p className="fs12">I also agree that Rapid Acceleration Partners, Inc or its representatives may contact me by email, phone, <br></br>at the email address or number I provide for marketing purposes.</p>
  <button type="submit" onClick={() => setshopChange(!shopStat)} className="bookbtn booktext text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300  text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Book Now</button>
  <div className="message">{message ? <p>{message}</p> : null}</div>
</form>

    
  </div>
  
</div>
<br/><br/>
</div>
  </Layout>)
}

export default IndexPage
